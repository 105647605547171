import React from 'react';

import "./App.css";

import NavBar from './navbar/NavBar';
import Home from './home/Home';

function App() {

  return (
    <div className="App">
      <NavBar />
      <Home />
    </div>
  );
}

export default App;